import React from "react";
import "./contactUs.scss";
import { useForm } from './useHubspotForm'

const Contact = () => {

    const { data, handleSubmit } = useForm({
        portalId: '26555333',
        formId: 'dfb34d91-78f9-4d1d-8eab-df05d0f0d4db'
    })
    return (
        <section className="contact container-fluid bg-90" id="contactUs">

            <div className="row justify-content-center align-items-center pb-5 mt-5">

                <div className="col-11 col-md-8 col-lg-6 mb-5 mt-5">
                    <div className="text-center mb-4 mt-5" style={{ color: 'white' }}>
                        <h2 className="getInTouch fw-bold text-center">Get in touch</h2>
                        <div>We're happy to hear from you. Contact us today to book a demo, partner with us,
                            apply for a job or simply get in touch to know more about AI Zwei</div>
                    </div>
                    <form style={{ color: '#F9FAFB' }} onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input name="firstname" type="text" className="form-control" id="name" aria-describedby="name" placeholder="Your name" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input name="email" type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Your email" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="subject" className="form-label">Subject</label>
                            <input name="TICKET.subject" type="text" className="form-control" id="subject" aria-describedby="subject" placeholder="Subject" required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="message" className="form-label">Message</label>
                            <textarea name="TICKET.content" className="form-control" id="message" aria-label="Message" aria-describedby="message" required placeholder="Type your message here"></textarea>
                        </div>
                        <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" id="privacyPolicy" required/>
                            <label className="form-check-label" htmlFor="privacyPolicy" style={{ color: 'white' }}>I agree to the <a href="/privacy">Privacy Policy</a></label>
                        </div>
                        <div className="d-grid gap-2">
                            {data && data.data && data.data.inlineMessage?<div className="fw-bold mt-2">Message sent!</div>:<button type="submit" className="button mb-5 justify-content-center">Send message</button>}
                        </div>

                    </form>
                </div>
            </div>
        </section>
    )
}

export default Contact;