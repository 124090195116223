import React from "react";
import DemocratisedIcon from "../../../assets/imgs/platformIcons/Democratised.svg";
import FastResults from "../../../assets/imgs/platformIcons/FastResults.svg";
import GuidanceIcon from "../../../assets/imgs/platformIcons/Guidance.svg";
import CollaborationIcon from "../../../assets/imgs/platformIcons/Collaboration.svg";
import TransparencyIcon from "../../../assets/imgs/platformIcons/Transparency.svg";
import ActionableInsights from "../../../assets/imgs/platformIcons/ActionableInsights.svg"
import "./ourPlatform.scss";

const Platform = () => {
    return (
        <section className="platform container-fluid mt-5 pt-5 whiteBg" id="ourPlatform">
            <div className="platformInner">
                <span id="floatingStick1"></span>
                <span id="floatingStick2"></span>
                <div className="row m-5"></div>
                <h2 className="platformTitle m-4 fw-bold text-center">What AI Zwei Delivers</h2>
                <br />
                <div className="row mt-5 m-4 m-md-5">
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={DemocratisedIcon} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Democratised Access</div>
                        <div className="textPart">
                            Revolutionises data science, enabling every non technical user to benefit from Al and data insights.
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={FastResults} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Fast Results</div>
                        <div className="textPart">
                            Creates models, reveals factors behind success and makes predictions in less than 20 minutes.
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={ActionableInsights} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Actionable Insights</div>
                        <div className="textPart">
                            Empowers users with insights to make confident, impactful decisions based on data and experience.
                        </div>
                    </div>
                </div>

                <div className="row mt-5 m-4 m-md-5">
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={GuidanceIcon} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Simple Process</div>
                        <div className="textPart">
                            Guides you through the process in a few easy-to-follow steps with no data science experience required.
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={CollaborationIcon} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Collaborative Tool</div>
                        <div className="textPart">
                            Multi-user access from one account fosters collaboration in the creation and improvement of Al models.
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5">
                        <img src={TransparencyIcon} alt="" className="plataformImageSize img-fluid mb-4" />
                        <div className="fw-bold">Transparency</div>
                        <div className="textPart">
                            Highlights what's driving your model's decision making process.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Platform;