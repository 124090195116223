import { useEffect, useRef } from 'react';
import "./aboutUs.scss";

const Canvas = () => {

    const backgroundLinearGradients = [
        'linear-gradient(180deg, #217BFF 0%, #F3AE3E 27.6%, #C0267D 58.85%, #02044C 100%)',
        'linear-gradient(180deg, #FFDA17 0%, #FF9432 19.27%, #88C43B 35.94%, #3CCC98 53.12%, #F9FB8C 100%)',
        'linear-gradient(180deg, #FD7817 0%, #D72411 17.19%, #5F4FCF 48.96%, #B1EBFD 100%)',
        'linear-gradient(180deg, #C5F462 0%, #F3E13E 22.92%, #26C092 58.33%, #007682 100%)'
    ]

    const elements = [
        {
            background: backgroundLinearGradients[0],
            position: [18, 73, 121],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [24, 68, 133],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[1],
            position: [-10, 49, 93],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [9, 50, 133],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [-14, 18, 132],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[4],
            position: [4, 13, 90],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [1, 27, 115],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [17, 17, 123],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [2, -6, 56],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [56, 2, 56],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [83, 40, 40],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [56, 50, 52],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [64, 72, -66],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[1],
            position: [54, 81, -82],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [-19, 81, -15],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [4, 98, 48],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [66, 11, 60],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [70, 0, 71],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[0],
            position: [70, 31, -19],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [56, 95, 53],
            ref: useRef()
        },
        {
            background: backgroundLinearGradients[3],
            position: [43, 99, -13],
            ref: useRef()
        }
    ];

    useEffect(() => {
        const options = {
            duration: 50000,
            iterations: "Infinity",
            animationTimingFunction: "linear"
        };

        elements.forEach(element => {
            const { position, ref } = element;
            const angle = position[2];

            ref.current.childNodes.forEach((child) => {
                const rateteDirection = angle > 0 ? 360 + angle : angle - 360;
                const positionReduce = 100 + (+ child.style.top.split('vh')[0]);
                child.animate([
                    {
                        transform: `rotate(${angle}deg)`,
                    },
                    {
                        transform: `rotate(${rateteDirection}deg)`,
                        top: positionReduce + 'vh'
                    },
                ], options)
            });
        })
    }, [])


    return <div id='wrappCanvas' >{
        elements.map((elem, indx) => {
            const [top, left] = elem.position;
            return (
                <div key={indx + '0'} ref={elem.ref} style={{}} className='canvasElement'>
                    <div key={indx + '1'} style={{ top: top - 100 + 'vh', left: left + 'vw', background: elem.background }} className='elementCanvas'></div>
                    <div key={indx + '2'} style={{ top: top + 'vh', left: left + 'vw', background: elem.background }} className='elementCanvas'></div>
                </div>)
        })}
    </div>
}

export default Canvas
