import * as React from 'react'
import AIZWEILOGO from '../../assets/imgs/aizwei-logo.svg';
import AIZWEILOGOBLACK from '../../assets/imgs/aizwei-logo-black.svg';
import { HashLink } from "react-router-hash-link";
import { Cookie } from "../../utils/cookie.ts";
import './navbar.scss';
import { AuthenticationService } from '../../services/AuthenticationService';


const NavBar = ({ modalState, logo }) => {
    const { activeModal, setActiveModal } = modalState;
    const authService = AuthenticationService.getInstance();

    const accountClick = async () => {
        const token = Cookie.get('token');
        if(token !== undefined) {
            await authService.authenticateByToken(token).catch(() => {
                Cookie.remove('token');
                setActiveModal(!activeModal);
            });
        } else{
            setActiveModal(!activeModal);
        }
    }

    return (
        <nav className="navbar navbar-expand-md fixed-top blurry" id="mainNav">
            <div className="blurrer"></div>
            <div className='container-fluid'>
                <a className="navbar-brand" href="/" id='link_logo'>
                    <img id='aizwei_logo' src={logo.src === 'white' ? AIZWEILOGO : AIZWEILOGOBLACK} alt={logo.alt} />
                </a>

                <div className="d-flex align-items-center" id="navbarCollapse">
                    <ul className="navbar-nav mr-auto NavMenuList">
                        <li className="nav-item active">
                            <HashLink className='nav-link element me-4' to={"/#about"}>About us</HashLink>
                        </li>
                        <li className="nav-item active">
                            <HashLink className="nav-link element me-4" to={"/#ourMotivation"}>Motivation</HashLink>
                        </li>
                        <li className="nav-item active">
                            <HashLink className={"nav-link element me-4"} to={"/#ourPlatform"}>Our Platform</HashLink>
                        </li>
                        <li className="nav-item active">
                            <HashLink className={"nav-link element me-4"} to={"/#theTeam"}>The team</HashLink>
                        </li>
                        <li className="nav-item active">
                            <HashLink className={"nav-link element me-4"} onClick={accountClick}>Account</HashLink>
                        </li>
                    </ul>

                    <HashLink id="elem-button" className={"nav-link button light"} to={"/#contactUs"}>Contact us</HashLink>                    
                </div>
            </div>
        </nav>
    );
}

export default NavBar;