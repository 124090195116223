import React, { useRef } from 'react';
import Khaleeq from '../../../assets/imgs/teamMembers/Khaleeq.jpg';
import Kico from '../../../assets/imgs/teamMembers/Kico.jpg';
import Helmut from '../../../assets/imgs/teamMembers/Helmut.jpg';
import Donka from '../../../assets/imgs/teamMembers/Donka.jpg';
import Tomas from '../../../assets/imgs/teamMembers/Tomas.jpg';
import Jose from '../../../assets/imgs/teamMembers/Jose.jpg';
import MarcFranco from '../../../assets/imgs/teamMembers/MarcFranco.jpg';
import MiguelDomingo from '../../../assets/imgs/teamMembers/MiguelDomingo.jpeg';
import Toni from '../../../assets/imgs/teamMembers/Toni.jpg';
import Gor from '../../../assets/imgs/teamMembers/_Gor.png';
import Cristian from '../../../assets/imgs/teamMembers/Cristian.jpg';
import Aleksandar from '../../../assets/imgs/teamMembers/Aleksandar.jpg';
import Nasir from '../../../assets/imgs/teamMembers/Nasir.jpg';
import Fiolla from '../../../assets/imgs/teamMembers/Fiolla.jpg';
import Guille from '../../../assets/imgs/teamMembers/Guille.jpg';
import Jiawen from '../../../assets/imgs/teamMembers/Jiawen.jpg';
import Ion from '../../../assets/imgs/teamMembers/Ion.jpg';

import './theTeam.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faAngleLeft, faAngleRight);

const teamMembers = [
    {
        id: (Math.random() * 1000),
        name: 'Khaleeq Aziz',
        position: 'CEO',
        photo: Khaleeq,
        facts: [
            'Co-founder and CEO of Symanto and Dailight',
            'Board member and Founder of Over 10 AI start-and-scale-ups',
            'YPO member',
            'MSc. Forensic Psychology, Aston University'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Francisco Rangel',
        position: 'Product Advisor',
        photo: Kico,
        facts: [
            'Chief product Officer at Symanto',
            'Former CTO of Autoritas (AI-based digital trasnformation company)',
            'Ph.D. Computer Science, Technical University of Valencia'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Jiawen Wu',
        position: 'Head of Product',
        photo: Jiawen,
        facts: [
            'Over 8 years AI Product Management experience',
            'Developed 5 AI-powered solutions from concept to final products',
            'Msc in Business Administration and Management'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Donka Parapanova',
        position: 'Senior Product Manager',
        photo: Donka,
        facts: [
            'Expertise in B2B and B2C product development in a variety of sectors including analytics, technology, telecom and FMCG',
            'MBA, FEBA, Sofia University'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Tomas Montiel',
        position: 'Product Data Scientist',
        photo: Tomas,
        facts: [
            'Expertise in Data Analysis and Visualizations, Statistics, Programming, and Machine Learning',
            'Ph.D., Industrial Engineering, Technical University of Valencia'
        ]
    }, {
        id: (Math.random() * 1000),
        name: 'Marc Franco Salvador',
        position: 'Scientific Advisor',
        photo: MarcFranco,
        facts: [
            'Chief Scientific Officer at Symanto',
            'Deep Learning Lecturer, Universitat de Barcelona',
            'City Lead Valencia AI, Spain AI',
            'Ph.D. Computer Science (Artificial Intelligence), Technical University of Valencia',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Jose Luis Benacloch',
        position: 'Head of Engineering',
        photo: Jose,
        facts: [
            'Expertise in software development, computer science and AI management BigBuy, A4BGroup and Tellmegen',
            'B.S., Computer Science and AI, Universitat Politècnica de València & AI specialization '
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Toni Alcalde',
        position: 'Senior Software Engineering Manager',
        photo: Toni,
        facts: [
            'Expertise in software development with VISUALFY and CAPGEMINI',
            'B.S., Computer science, Universitat Politècnica de València',
            'MSc, PARALLEL AND DISTRIBUTED COMPUTING Parallel and distributed Computing'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Aleksandar Stankovski',
        position: 'Software Engineer',
        photo: Aleksandar,
        facts: [
            'Expertise in software development and data science',
            'B.S., Computer Software Engineering, Ss. Cyril and Methodius University Skopje',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Cristian Baghiu',
        position: 'Software Engineer',
        photo: Cristian,
        facts: [
            'Mid Software Engineer and full stack developer',
            'Experience in migrations of web platforms and mobile environments',
            'MSc, Software Systems Engineering and Technology, Universidad Politecnica of Valencia, Valencia-Spain',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Gor Vardanyan',
        position: 'Software Engineer',
        photo: Gor,
        facts: [
            'Mid Software Engineer and full stack developer',
            'Studied a Bachelor of Design, in EASD (School of Arts and Superior Design) Valencia-Spain',
            '3D modeling designer',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Nasir Sinani',
        position: 'Senior Software Engineer',
        photo: Nasir,
        facts: [
            'Expertise in software engineering and machine learning with Technoperia',
            'MSc, Computer Engineering, Yildiz Technical University, Istanbul-Turkey. Research focused on deep learning and wireless communication',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Fjolla Fetaji',
        position: 'Junior Software Engineer',
        photo: Fiolla,
        facts: [
            'Experience in Data Science, Machine Learning, and Programming.',
            'B.S., Software Engineering, Faculty of Computer Science and Engineering, Ss. Cyril and Methodius University.',
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Guillermo Martínez',
        position: 'UX/UI Lead',
        photo: Guille,
        facts: [
            'Expertise in UX/UI design for digital products and web development experience across a variety of industries.',
            'Degree in Industrial design, Universidad Alfonso X El Sabio, Madrid and Master in Product design, CEU, Valencia'
        ]
    },
    {
        id: (Math.random() * 1000),
        name: 'Miguel Gauses Domingo',
        position: 'Technical Product Owner',
        photo: MiguelDomingo,
        facts: [
            'Expertise in software development at Serfruit, MyTaskPanel and Capgemini',
            'BSc in Computer Science, Universitat Politécnica de València',
        ]
    }
]


const Team = () => {
    const ref = useRef(null);
    let mouseDown = false;
    let pos = 0;
    let currentPos = 0;

    const sideScroll = (
        element,
        speed,
        step
    ) => {
        const distance = element.getElementsByClassName('cardsList')[0].clientWidth;
        let scrollAmount = 0;

        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    };

    const sideDragStart = (
        event,
        element
    ) => {
        mouseDown = true;
        currentPos = event.clientX;
        element.classList.add('js-dragging');
    };

    const sideDragMove = (
        event,
        element
    ) => {
        if (mouseDown) {
            pos = currentPos - event.clientX;
            element.scrollLeft += pos
            currentPos = event.clientX;
        }
    };

    const sideDragStop = (
        element
    ) => {
        mouseDown = false;
        element.classList.remove('js-dragging');
    };


    return (
        <section className='team pb-5 mt-5 pt-5 ms-5 whiteBg' id='theTeam'>
            <div className='teamHeader'>
                <div className='teamHeaderTitle'>
                    <h2 className='team__title h1 fw-bold h-section'>The team</h2>
                </div>
                <div className='scrollBtnGroup'>
                    <button className='btn scrollBtn border me-1 mt-1 mb-1' onClick={() => sideScroll(ref.current, 13, -10)}>
                        <FontAwesomeIcon icon={'angle-left'} />
                    </button>
                    <button className='btn scrollBtn border ms-1 mt-1 mb-1' onClick={() => sideScroll(ref.current, 13, 10)}>
                        <FontAwesomeIcon icon={'angle-right'} />
                    </button>
                </div>
            </div>
            <div
                className=' teamMembers'
                ref={ref}
                onMouseDown={(event) => sideDragStart(event, ref.current)}
                onMouseMove={(event) => sideDragMove(event, ref.current)}
                onMouseUp={(element) => sideDragStop(ref.current)}
            >
                {
                    teamMembers.map(item => (
                        <div key={item.id} className='cardsList'>
                            <div className='card border-0'>
                                <div style={{ height: '308px', background: `url(${item.photo}) no-repeat center -1rem`, backgroundSize: 'cover' }} className='card-img' />
                                <div className='card-body'>
                                    <h5 className='card-title fw-bold' style={{ color: '#101828' }}>{item.name}</h5>
                                    <div className='card-text'>
                                        <p className='workPosition'>{item.position}</p>
                                        <ul className='facts'>
                                            {
                                                item.facts.map(fact => (
                                                    <li key={Math.random()}>{fact}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default Team;