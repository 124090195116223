import { AuthenticationClient } from "../client/AuthenticationClient";
import { User } from "../models/User";

export class AuthenticationService {

    #authenticationClient;

    #_authToken;

    #_refreshToken;

    #authTokenExpiration;

    #user;

    instance;

    static getInstance() {
        if (!AuthenticationService.instance) {
            AuthenticationService.instance = new AuthenticationService();
        }

        return AuthenticationService.instance;
    }
    constructor() {
        this.#authenticationClient = AuthenticationClient.getInstance();
        this.#_authToken = localStorage.getItem('token') || '';
        this.#_refreshToken = '';
        this.#user = null;
        this.#authTokenExpiration = new Date();
    }

    async authenticateByEmailAndPassword(email, password) {
        const loginResponse = await this.#authenticationClient.login(email, password);
        this.#_authToken = '';
        this.#_refreshToken = '';
        this.#user = null;
        this.#authTokenExpiration = new Date();
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data && loginResponse.data.token) {
            this.#_authToken = loginResponse.data.token;
            this.#_refreshToken = loginResponse.data.refreshToken;
            const newUser = this.#createUser(loginResponse.data);
            this.setUser(newUser);
            this.#authTokenExpiration.setDate(this.#authTokenExpiration.getDate() + 1);
            console.log('User authorized')
            this.openMarketPlace();
        } else {
            throw Error('Not authorized')
        }
    }

    async authenticateByToken(token) {
        const loginResponse = await this.#authenticationClient.getProfile(token);
        this.#_authToken = '';
        this.#_refreshToken = '';
        this.#user = null;
        this.#authTokenExpiration = new Date();
        if (loginResponse.status && loginResponse.status === 200 && loginResponse.data && token) {
            this.#_authToken = token;
            this.#_refreshToken = loginResponse.data.refreshToken;
            const newUser = this.#createUser(loginResponse.data, token);
            this.setUser(newUser);
            this.#authTokenExpiration.setDate(this.#authTokenExpiration.getDate() + 1);
            console.log('User authorized');
            this.openMarketPlace();
        } else {
            throw Error('Not authorized')
        }
    }

    openMarketPlace = () => {
        const urlMarketPlace = '' + process.env.REACT_APP_MARKETPLACE_BASE_URL + '' + process.env.REACT_APP_MARKETPLACE_BASE_URL_PREFIX_URI;
        window.location.href = urlMarketPlace + '/?token=' + encodeURIComponent(this.#_authToken) + '?refreshToken=' + encodeURIComponent(this.#_refreshToken);
    }

    #createUser(data, token) {
        const user = new User(
            data?.id || data?.user?.id,
            data?.firstName || data?.user?.firstName,
            data?.lastName || data?.user?.lastName,
            data?.email || data?.user?.email,
            data?.scope || data?.user?.scope,
            new Date(data?.createdDate) || new Date(data?.user?.createdDate),
            data?.slackId || data?.user?.slackId,
            data?.accountId || data?.user?.accountId,
            token || null);
        return user;
    }

    setUser(user) {
        this.#user = user;
    }

    async recoverPassword(email) {
        const recoverPasswordResponse = await this.#authenticationClient.recoverPasswordByEmail(email);
        if (!recoverPasswordResponse || !recoverPasswordResponse.status || recoverPasswordResponse.status !== 204) {
            throw Error('User not exists')
        }
    }

    async recoverPasswordToken(email) {
        const recoverPasswordResponse = await this.#authenticationClient.recoverPasswordToken(email);
        if (!recoverPasswordResponse || !recoverPasswordResponse.status || recoverPasswordResponse.status !== 204) {
            throw Error('User not exists')
        }
        return recoverPasswordResponse;
    }

    get authToken() {
        return this.#_authToken;
    }

    get refreshToken() {
        return this.#_refreshToken;
    }

    isAuthenticated() {
        const now = new Date();
        if (this.#user && this.#authTokenExpiration.getTime() > now.getTime()) {
            return true;
        }
        return false;
    }

    async userExists(email) {
        const checkEmailIsValidResponse = await this.#authenticationClient.checkEmailIsValid(email);
        if (!checkEmailIsValidResponse || !checkEmailIsValidResponse.status || checkEmailIsValidResponse.status !== 204) {
            throw Error('User not exists')
        }
    }

    async resetPassword(token, newPassword, confirmPassword) {
        const userResetPasswordResponse = await this.#authenticationClient.resetPassword(token, newPassword, confirmPassword);
        if (!userResetPasswordResponse || !userResetPasswordResponse.status || userResetPasswordResponse.status !== 204) {
            throw Error('User not exists')
        }
        return userResetPasswordResponse;
    }
}