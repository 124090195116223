import React, { useEffect, useRef, useState } from 'react';
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from 'mdb-react-ui-kit';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import './resetPassword.scss';

const ResetPasswordModal = ({ modalState }) => {
    const authenticationService = AuthenticationService.getInstance();
    const { activeModal, setActiveModal } = modalState;

    const [displayMessage, setDisplayMessage] = useState();
    const [token, setToken] = useState();
    const [mode, setMode] = useState();

    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();

    const btnResetPassword = useRef();
    const inputFields = useRef([]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('token') && searchParams.has('mode')) {
            const token = searchParams.get('token');
            setToken(token);
            const mode = searchParams.get('mode');
            setMode(mode);
        }
    }, [])

    useEffect(() => {
        const regexPassword = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;

        if (newPassword) {
            if (!regexPassword.test(newPassword)) {
                setDisplayMessage({ text: 'The password introduced does not meet the requirements. Passwords must have at least 8 characters, one letter uppercase, one letter lowercase, a number and a special character ($, %, &, etc.)' });
            } else if (confirmNewPassword && newPassword !== confirmNewPassword) {
                setDisplayMessage({ text: 'Passwords do not match' });
            } else {
                setDisplayMessage();
            }
        } else {
            setDisplayMessage();
        }
    }, [newPassword, confirmNewPassword])

    const resetPassword = async (newPassword, confirmNewPassword) => {
        try {
            authenticationService.resetPassword(token, newPassword, confirmNewPassword)
                .then((response) => {
                    if (response.status === 204) {
                        setToken(null);
                        let urlMarketPlace = '' + window.location.origin;
                        if (mode === '1') {
                            urlMarketPlace = '' + process.env.REACT_APP_ASSISTANT_FRONT_BASE_URL;
                        }
                        window.location.href = urlMarketPlace;
                    }
                })
                .catch((error) => console.error(error));
        } catch (error) {
            setDisplayMessage({ text: 'Something went wrong, please check your credentials or contact the support team' });
        }
    }

    function handleKeyDown(event) {
        if (event.key === "Enter") {
            event.preventDefault();

            const { current: inputs } = inputFields;
            const index = inputs.indexOf(event.target);
            const nextIndex = (index + 1) % 2;

            if (index > -1 && inputs[nextIndex]) {
                if (btnResetPassword.current.disabled) {
                    inputs[nextIndex].focus();
                } else {
                    btnResetPassword.current.focus();
                }
            }
        }
    }

    return (
        <>
            <MDBModal show={activeModal} setShow={setActiveModal} tabIndex='-1' closeOnEsc={false} staticBackdrop={true}>
                <MDBModalDialog style={{ width: '100%', height: '50%', transform: 'translateY(50%)' }}>
                    <MDBModalContent className='modelContent'>
                        <MDBModalHeader className='modalHeader'>
                            <MDBModalTitle className='modalTitle'>Reset password</MDBModalTitle>
                            <MDBModalTitle className='modalDescription'>
                                Please check your email to continue the process. Don't forget to check your spam/junk folder too.
                            </MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody
                            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '0.5rem' }}
                        >
                            <form action=''>
                                <label htmlFor='modalNewPassword' className='labelForm spaceLabels'>New Password</label>
                                <input
                                    ref={element => inputFields.current.push(element)}
                                    name='modalNewPassword'
                                    style={displayMessage && newPassword ? { borderColor: 'red' } : {}}
                                    className='form-control'
                                    type='password'
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    placeholder='Password'
                                    autoComplete='off'
                                    tabIndex={1}
                                />
                                <label htmlFor='modalConfirmNewPassword' className='labelForm spaceLabels'>Confirm New Password</label>
                                <input
                                    ref={element => inputFields.current.push(element)}
                                    name='modalConfirmNewPassword'
                                    style={displayMessage && confirmNewPassword ? { borderColor: 'red' } : {}}
                                    className='form-control'
                                    type='password'
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    placeholder='Confirm password'
                                    autoComplete='off'
                                />
                            </form>
                            {
                                (displayMessage && newPassword) || (displayMessage && confirmNewPassword)
                                    ? <div
                                        style={displayMessage.style ?? { color: 'red' }}
                                        className='errorMessage'
                                    >
                                        {displayMessage.text}
                                    </div>
                                    : <></>
                            }
                        </MDBModalBody>
                        <MDBModalFooter style={{ justifyContent: 'center', borderTop: 'none' }}>
                            <button
                                ref={btnResetPassword}
                                disabled={!(newPassword && confirmNewPassword) || displayMessage}
                                className='btnPassword' onClick={() => resetPassword(newPassword, confirmNewPassword)}>
                                Save new password
                            </button>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default ResetPasswordModal;