import React from "react";
const Impressum = () => {
    return (
        <section className="mt-5 p-5 whiteBg" id="ourPlatform">
            <p>
                AI Zwei GmbH <br/>
                Zollhafen, 2-4 <br/>
                50678 Cologne (Germany) <br/>
                Registergericht: Amtsgericht Köln, HRB 99840 <br/>
                USt-IdNr.: DE324857456  <br/>
                Phone: +49 151 58089056 <br/>
                Mail: info@aizwei.com
            </p>
            <p>
                The use of our website is generally possible without providing personal data. Insofar as personal data (e.g. name, address or e-mail addresses) is collected on our pages, this is always done on a voluntary basis as far as possible. This data will not be passed on to third parties without your express consent.
                We would like to point out that data transmission on the Internet (e.g. communication by e-mail) can be subject to security gaps. A complete protection of the data against access by third parties is not possible.
                The use of contact data published within the scope of the imprint obligation by third parties to send unsolicited advertising and information materials is hereby expressly prohibited. The operators of this website expressly reserve the right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising materials.
                This website uses Google Analytics, a web analytics service provided by Google Inc. (“Google”). Google Analytics uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Under no circumstances will Google associate your IP address with other Google data. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
                Our offer contains links to external websites of third parties over whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages. The linked pages were checked for possible legal infringements at the time of linking. Illegal contents were not recognisable at the time of linking. A permanent control of the contents of the linked pages is not reasonable without concrete evidence of an infringement. As soon as we become aware of any legal infringements, we will remove such links immediately.
                Managing director with sole power of representation: Khaleeq Aziz, Martin Schürmann
            </p>
        </section>
    )
}

export default Impressum;