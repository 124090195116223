export class User {
    id;
    firstName;
    lastName;
    email;
    scope;
    createdDate;
    slackId;
    accountId;
    token;

    constructor(id, firstName, lastName, email, scope, createdDate, slackId, accountId, token) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.scope = scope;
        this.createdDate = createdDate;
        this.slackId = slackId;
        this.accountId = accountId;
        this.token = token;
    }
}