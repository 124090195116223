import React, { useEffect, useRef } from "react";
const TabItem = ({ id, title, activeTab, setActiveTab, intervalTime, handleTabClick }) => {

    const progressBar1 = useRef(null);
    const progressBar2 = useRef(null);
    const currentActiveTab = activeTab === id;

    useEffect(() => {
        if (progressBar1.current && progressBar2.current && currentActiveTab) {
            progressBar1.current.animate([{ height: "0%" }, { height: "100%" }], {
                duration: intervalTime,
                fill: "none",
            })
            progressBar2.current.animate([{ width: "0%" }, { width: "100%" }], {
                duration: intervalTime,
                fill: "none",
            })
        }
    }, [activeTab, currentActiveTab, intervalTime]);


    const activeTabShow = (tab) => {
        if (activeTab === tab) {
            return (
                <div className="reducedBar">
                    <div
                        className="progress"
                        id='progressbar'
                        ref={progressBar2}
                    >
                        <div className="progress__bar" id={"timedProgressBar" + id} role="progressbar"></div>
                    </div>
                </div>
            )
        }

        return <div className="progress"></div>;
    }

    return (
        <li 
            className={currentActiveTab ? "active" : ""}
            onClick={() => handleTabClick(id)}
        >
            <div className="progressBarWrapper1">
                <div
                    className="progress"
                    id='progressbar'
                    ref={progressBar1}
                >
                    <div className="progress__bar" id={"timedProgressBar" + id} role="progressbar"></div>
                </div>
            </div>
            <div className="progressBarWrapper2">
                {activeTabShow('tab1')}
                {activeTabShow('tab2')}
                {activeTabShow('tab3')}
            </div>
            <p className="fw-bold tab-item__title">{title}</p>
        </li>
    );
};
export default TabItem;