import React from "react";
const Privacy = () => {
    return (
        <section id="PrivacyPolicy" className="mt-5 p-5 whiteBg">

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>
                            Privacy policy
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <p>Personal data (usually referred to just as "data" below) will only be processed by us to the extent necessary and for the purpose of providing a functional and user-friendly website, including its contents, and the services offered there.
                            Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter referred to as the "GDPR"), "processing" refers to any operation or set of operations such as collection, recording, organization, structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction performed on personal data, whether by automated means or not.
                            The following privacy policy is intended to inform you in particular about the type, scope, purpose, duration, and legal basis for the processing of such data either under our own control or in conjunction with others. We also inform you below about the third-party components we use to optimize our website and improve the user experience which may result in said third parties also processing data they collect and control.
                        </p>

                        <br/><br/>
                        
                        <h3 id="aboutus">I. Information about us as controllers of your data</h3>
                        <p>
                            The party responsible for this website (the "controller") for purposes of data protection law is: <br /><br />
                            AI ZWEI GmbH <br /><br />
                            Im Zollhafen 2-4 <br />
                            50678 Cologne <br />
                            Germany<br /><br />
                            Tel.: +49 151 58089056<br />
                            E-Mail: info@aizwei.com<br /><br />
                        </p>
                        
                        <h3 id="rights">II. The rights of users and data subjects</h3>
                        <p>With regard to the data processing to be described in more detail below, users and data subjects have the right</p>

                        <ul className="ms-2">
                            <li>
                                to confirmation of whether data concerning them is being processed, information about the data being processed, further information about the nature of the data processing, and copies of the data (cf. also Art. 15 GDPR);
                            </li>
                            <li>
                                to correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);
                            </li>
                            <li>
                                to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;
                            </li>
                            <li>
                                to receive copies of the data concerning them and/or provided by them and to have the same transmitted to other providers/controllers (cf. also Art. 20 GDPR);
                            </li>
                            <li>
                                to file complaints with the supervisory authority if they believe that data concerning them is being processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).
                            </li>
                        </ul>
                        
                        <p>
                            In addition, the controller is obliged to inform all recipients to whom it discloses data of any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves a disproportionate effort. Nevertheless, users have a right to information about these recipients.
                            <br />
                            Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller's future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data processing for the purpose of direct advertising is permissible.
                            <br /><br />
                        </p>

                        <h3 id="aboutdata">III. Information about the data processing</h3>
                        <p>
                            Your data processed when using our website will be deleted or blocked as soon as the purpose for its storage ceases to apply, provided the deletion of the same is not in breach of any statutory storage obligations or unless otherwise stipulated below.
                        </p>
                        <br /><br />
                        
                        <h5>Server data</h5>
                        <p>
                            For technical reasons, the following data sent by your internet browser to us or to our server provider will be collected, especially to ensure a secure and stable website: These server log files record the type and version of your browser, operating system, the website from which you came (referrer URL), the webpages on our site visited, the date and time of your visit, as well as the IP address from which you visited our site.
                        </p>
                        <p>
                            The data thus collected will be temporarily stored, but not in association with any other of your data.
                        </p>
                        <p>
                            The basis for this storage is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the improvement, stability, functionality, and security of our website.
                        </p>
                        <p>
                            The data will be deleted within no more than seven days, unless continued storage is required for evidentiary purposes. In which case, all or part of the data will be excluded from deletion until the investigation of the relevant incident is finally resolved.
                        </p>
                        
                        <h5>Cookies</h5>

                        <p>
                            <b>a) Session cookies</b><br /><br />
                            
                            We use cookies on our website. Cookies are small text files or other storage technologies stored on your computer by your browser. These cookies process certain specific information about you, such as your browser, location data, or IP address.
                            This processing makes our website more user-friendly, efficient, and secure, allowing us, for example, to display our website in different languages or to offer a shopping cart function.
                            The legal basis for such processing is Art. 6 Para. 1 lit. b) GDPR, insofar as these cookies are used to collect data to initiate or process contractual relationships.
                            If the processing does not serve to initiate or process a contract, our legitimate interest lies in improving the functionality of our website. The legal basis is then Art. 6 Para. 1 lit. f) GDPR.
                            When you close your browser, these session cookies are deleted.
                        </p>
                        
                        <p>
                            <b>b) Third-party cookies</b><br /><br />
                            If necessary, our website may also use cookies from companies with whom we cooperate for the purpose of advertising, analyzing, or improving the features of our website.
                            Please refer to the following information for details, in particular for the legal basis and purpose of such third-party collection and processing of data collected through cookies.
                        </p>

                        <p>
                            <b>c) Disabling cookies</b><br /><br />
                            You can refuse the use of cookies by changing the settings on your browser. Likewise, you can use the browser to delete cookies that have already been stored. However, the steps and measures required vary, depending on the browser you use. If you have any questions, please use the help function or consult the documentation for your browser or contact its maker for support. Browser settings cannot prevent so-called flash cookies from being set. Instead, you will need to change the setting of your Flash player. The steps and measures required for this also depend on the Flash player you are using. If you have any questions, please use the help function or consult the documentation for your Flash player or contact its maker for support.
                            <br /><br />If you prevent or restrict the installation of cookies, not all of the functions on our site may be fully usable.
                        </p>

                        <h5>Contact</h5>
                        <p>
                            If you contact us via email or the contact form, the data you provide will be used for the purpose of processing your request. We must have this data in order to process and answer your inquiry; otherwise we will not be able to answer it in full or at all.
                            <br /><br />The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR.<br /><br />
                            Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation to store your data, such as if an order or contract resulted therefrom.
                        </p>
                        
                        <h5>Twitter</h5>
                        <p>
                            We maintain an online presence on Twitter to present our company and our services and to communicate with customers/prospects. Twitter is a service provided by Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.
                        </p>
                        <p>
                            We would like to point out that this might cause user data to be processed outside the European Union, particularly in the United States. This may increase risks for users that, for example, may make subsequent access to the user data more difficult. We also do not have access to this user data. Access is only available to Twitter. Twitter Inc. is certified under the Privacy Shield and committed to adhering to European privacy standards.
                        </p>

                        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a>
                        
                        <br /><br />The privacy policy of Twitter can be found at<br /><br />
                        
                        <a href="https://twitter.com/privacy">https://twitter.com/privacy</a>
                        
                        <br /><br />
                        
                        <h5>LinkedIn</h5><br /><br />
                        <p>
                            We maintain an online presence on LinkedIn to present our company and our services and to communicate with customers/prospects. LinkedIn is a service of LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085, USA.
                        </p>
                        <p>
                            We would like to point out that this might cause user data to be processed outside the European Union, particularly in the United States. This may increase risks for users that, for example, may make subsequent access to the user data more difficult. We also do not have access to this user data. Access is only available to LinkedIn. LinkedIn Corporation is certified under the Privacy Shield and committed to comply with European privacy standards.
                        </p>
                        
                        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active</a>
                        
                        <br /><br />The LinkedIn privacy policy can be found here:<br /><br />
                        
                        <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
                        
                        <br /><br />
                        
                        <h5>Facebook</h5><br /><br />
                        <p>
                            To advertise our products and services as well as to communicate with interested parties or customers, we have a presence on the Facebook platform.<br />

                            On this social media platform, we are jointly responsible with Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland.<br />

                            The data protection officer of Facebook can be reached via this contact form:

                            <br /><br /><a href="https://www.facebook.com/help/contact/540977946302970">https://www.facebook.com/help/contact/540977946302970</a><br /><br />

                            We have defined the joint responsibility in an agreement regarding the respective obligations within the meaning of the GDPR. This agreement, which sets out the reciprocal obligations, is available at the following link:
                            
                            <br /><br /><a href="https://www.facebook.com/legal/terms/page_controller_addendum">https://www.facebook.com/legal/terms/page_controller_addendum</a><br /><br />

                            The legal basis for the processing of the resulting and subsequently disclosed personal data is Art. 6 para. 1 lit. f GDPR. Our legitimate interest lies in the analysis, communication, sales, and promotion of our products and services.
                            <br /><br />
                            
                            The legal basis may also be your consent per Art. 6 para. 1 lit. a GDPR granted to the platform operator. Per Art. 7 para. 3 GDPR, you may revoke this consent with the platform operator at any time with future effect.
                            <br /><br />
                            
                            When accessing our online presence on the Facebook platform, Facebook Ireland Ltd. as the operator of the platform in the EU will process your data (e.g. personal information, IP address, etc.).
                            <br /><br />
                            
                            This data of the user is used for statistical information on the use of our company presence on Facebook. Facebook Ireland Ltd. uses this data for market research and advertising purposes as well as for the creation of user profiles. Based on these profiles, Facebook Ireland Ltd. can provide advertising both within and outside of Facebook based on your interests. If you are logged into Facebook at the time you access our site, Facebook Ireland Ltd. will also link this data to your user account.
                            <br /><br />
                            
                            If you contact us via Facebook, the personal data your provide at that time will be used to process the request. We will delete this data once we have completely responded to your query, unless there are legal obligations to retain the data, such as for subsequent fulfillment of contracts.
                            <br /><br />
                            
                            Facebook Ireland Ltd. might also set cookies when processing your data.
                            <br /><br />

                            If you do not agree to this processing, you have the option of preventing the installation of cookies by making the appropriate settings in your browser. Cookies that have already been saved can be deleted at any time. The instructions to do this depend on the browser and system being used. For Flash cookies, the processing cannot be prevented by the settings in your browser, but instead by making the appropriate settings in your Flash player. If you prevent or restrict the installation of cookies, not all of the functions of Facebook may be fully usable.
                            <br /><br />

                            Details on the processing activities, their suppression, and the deletion of the data processed by Facebook can be found in its privacy policy:
                            
                            <br /><br /><a href="https://www.facebook.com/privacy/explanation">https://www.facebook.com/privacy/explanation</a><br /><br />

                            It cannot be excluded that the processing by Facebook Ireland Ltd. will also take place in the United States by Facebook Inc., 1601 Willow Road, Menlo Park, California 94025.
                            <br /><br />

                            Facebook Inc. has submitted to the EU-US Privacy Shield, thereby complying with the data protection requirements of the EU when processing data in the USA.
                            
                            <br /><br /><a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a><br /><br />
                        </p>
                        
                        
                        <h5>Instagram</h5><br /><br />
                        <p>
                            To advertise our products and services as well as to communicate with interested parties or customers, we have a presence on the Instagram platform.
                            <br /><br />

                            On this social media platform, we are jointly responsible with Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.
                            <br /><br />

                            The data protection officer of Instagram can be reached via this contact form:

                            <br /><br /><a href="https://www.facebook.com/help/contact/540977946302970">https://www.facebook.com/help/contact/540977946302970</a><br /><br />

                            We have defined the joint responsibility in an agreement regarding the respective obligations within the meaning of the GDPR. This agreement, which sets out the reciprocal obligations, is available at the following link:
                            
                            <br /><br /><a href="https://www.facebook.com/legal/terms/page_controller_addendum">https://www.facebook.com/legal/terms/page_controller_addendum</a><br /><br />


                            The legal basis for the processing of the resulting and subsequently disclosed personal data is Art. 6 para. 1 lit. f GDPR. Our legitimate interest lies in the analysis, communication, sales, and promotion of our products and services.
                            <br /><br />

                            The legal basis may also be your consent per Art. 6 para. 1 lit. a GDPR granted to the platform operator. Per Art. 7 para. 3 GDPR, you may revoke this consent with the platform operator at any time with future effect.
                            <br /><br />

                            When accessing our online presence on the Instagram platform, Facebook Ireland Ltd. as the operator of the platform in the EU will process your data (e.g. personal information, IP address, etc.).
                            <br /><br />

                            This data of the user is used for statistical information on the use of our company presence on Instagram. Facebook Ireland Ltd. uses this data for market research and advertising purposes as well as for the creation of user profiles. Based on these profiles, Facebook Ireland Ltd. can provide advertising both within and outside of Instagram based on your interests. If you are logged into Instagram at the time you access our site, Facebook Ireland Ltd. will also link this data to your user account.
                            <br /><br />

                            If you contact us via Instagram, the personal data your provide at that time will be used to process the request. We will delete this data once we have completely responded to your query, unless there are legal obligations to retain the data, such as for subsequent fulfillment of contracts.
                            <br /><br />

                            Facebook Ireland Ltd. might also set cookies when processing your data.
                            <br /><br />

                            If you do not agree to this processing, you have the option of preventing the installation of cookies by making the appropriate settings in your browser. Cookies that have already been saved can be deleted at any time. The instructions to do this depend on the browser and system being used. For Flash cookies, the processing cannot be prevented by the settings in your browser, but instead by making the appropriate settings in your Flash player. If you prevent or restrict the installation of cookies, not all of the functions of Instagram may be fully usable.
                            <br /><br />

                            Details on the processing activities, their suppression, and the deletion of the data processed by Instagram can be found in its privacy policy:
                            
                            <br /><br /><a href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a><br /><br />

                            It cannot be excluded that the processing by Facebook Ireland Ltd. will also take place in the United States by Facebook Inc., 1601 Willow Road, Menlo Park, California 94025.
                            <br /><br />

                            Facebook Inc. has submitted to the EU-US Privacy Shield, thereby complying with the data protection requirements of the EU when processing data in the USA.
                            
                            <br /><br /><a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a><br /><br />
                        </p>

                        <h5>Social media links via graphics</h5>
                        <p>
                            We also integrate the following social media sites into our website. The integration takes place via a linked graphic of the respective site. The use of these graphics stored on our own servers prevents the automatic connection to the servers of these networks for their display. Only by clicking on the corresponding graphic will you be forwarded to the service of the respective social network.
                            <br /><br />

                            Once you click, that network may record information about you and your visit to our site. It cannot be ruled out that such data will be processed in the United States.
                            <br /><br />

                            Initially, this data includes such things as your IP address, the date and time of your visit, and the page visited. If you are logged into your user account on that network, however, the network operator might assign the information collected about your visit to our site to your personal account. If you interact by clicking Like, Share, etc., this information can be stored your personal user account and possibly posted on the respective network. To prevent this, you need to log out of your social media account before clicking on the graphic. The various social media networks also offer settings that you can configure accordingly.
                            <br /><br />

                            The following social networks are integrated into our site by linked graphics:
                            <br /><br />

                            Facebook
                            <br /><br />

                            Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, a subsidiary of Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304, USA.
                            
                            <br /><br />Privacy Policy:<a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a><br /><br />
                            
                            <br /><br />EU-US Privacy Shield <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a><br /><br />
                            Twitter
                            <br /><br />
                            Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA
                            
                            <br /><br /><a href="Privacy Policy: https://twitter.com/privacy">Privacy Policy: https://twitter.com/privacy</a><br /><br />

                            EU-US Privacy Shield
                            
                            <br /><br /><a href="https://www.privacyshield.gov/…0000TORzAAO&status=Active">https://www.privacyshield.gov/…0000TORzAAO&status=Active</a><br /><br />

                            LinkedIn
                            <br /><br />
                            LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, a subsidiary of LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.
                            
                            <br /><br /><a href="Privacy Policy: https://www.linkedin.com/legal/privacy-policy">Privacy Policy: https://www.linkedin.com/legal/privacy-policy</a><br /><br />
                            
                            <br /><br /><a href="EU-US Privacy Shield https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active">EU-US Privacy Shield https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active</a><br /><br />
                            Facebook plug-in
                            <br /><br />
                            Our website uses the plug-in of the Facebook social network. Facebook.com is a service provided by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA. In the EU, this service is also operated by Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, hereinafter both referred to as "Facebook."
                            <br /><br />
                            Through certification according to the EU-US Privacy Shield
                        
                            <br /><br /><a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a><br /><br />
                            
                            Facebook guarantees that it will follow the EU's data protection regulations when processing data in the United States.
                            <br /><br />
                            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in improving the quality of our website.
                            <br /><br />
                            Further information about the possible plug-ins and their respective functions is available from Facebook at
                            
                            <br /><br /><a href="https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins</a><br /><br />
                            
                            If the plug-in is stored on one of the pages you visit on our website, your browser will download an icon for the plug-in from Facebook's servers in the USA. For technical reasons, it is necessary for Facebook to process your IP address. In addition, the date and time of your visit to our website will also be recorded.
                            <br /><br />
                            If you are logged in to Facebook while visiting one of our plugged-in websites, the information collected by the plug-in from your specific visit will be recognized by Facebook. The information collected may then be assigned to your personal account at Facebook. If, for example, you use the Facebook Like button, this information will be stored in your Facebook account and published on the Facebook platform. If you want to prevent this, you must either log out of Facebook before visiting our website or use an add-on for your browser to prevent the Facebook plug-in from loading.
                            <br /><br />
                            Further information about the collection and use of data as well as your rights and protection options in Facebook's privacy policy found at
                            
                            <br /><br /><a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a><br /><br />
                            
                            Twitter plug-in
                            <br /><br />
                            Our website uses the plug-in of the Twitter social network. The Twitter service is operated by Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA ("Twitter").
                            <br /><br />
                            Through certification according to the EU-US Privacy Shield
                            
                            <br /><br /><a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active</a><br /><br />
                            
                            Twitter guarantees that it will follow the EU's data protection regulations when processing data in the United States.
                            <br /><br />
                            The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in improving the quality of our website.
                            <br /><br />
                            If the plug-in is stored on one of the pages you visit on our website, your browser will download an icon for the plug-in from Twitter's servers in the USA. For technical reasons, it is necessary for Twitter to process your IP address. In addition, the date and time of your visit to our website will also be recorded.
                            <br /><br />
                            If you are logged in to Twitter while visiting one of our plugged-in websites, the information collected by the plug-in from your specific visit will be recognized by Twitter. The information collected may then be assigned to your personal account at Twitter. If, for example, you use the Twitter Tweet button, this information will be stored in your Twitter account and may be published on the Twitter platform. To prevent this, you must either log out of
                            <br /><br />
                            Twitter before visiting our site or make the appropriate settings in your Twitter account.
                            <br /><br />
                            Further information about the collection and use of data as well as your rights and protection options in Twitter's privacy policy found at
                            
                            <br /><br /><a href="https://twitter.com/privacy">https://twitter.com/privacy</a><br /><br />
                            
                            Google Analytics
                            <br /><br />
                            We use Google Analytics on our website. This is a web analytics service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland (hereinafter: Google).
                            <br /><br />
                            Through certification according to the EU-US Privacy Shield
                            
                            <br /><br /><a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a><br /><br />
                            
                            Google guarantees that it will follow the EU's data protection regulations when processing data in the United States.
                            <br /><br />
                            The Google Analytics service is used to analyze how our website is used. The legal basis is Art. 6 Para. 1 lit. f) GDPR. Our legitimate interest lies in the analysis, optimization, and economic operation of our site.
                            <br /><br />
                            Usage and user-related information, such as IP address, place, time, or frequency of your visits to our website will be transmitted to a Google server in the United States and stored there. However, we use Google Analytics with the so-called anonymization function, whereby Google truncates the IP address within the EU or the EEA before it is transmitted to the US.
                            <br /><br />
                            The data collected in this way is in turn used by Google to provide us with an evaluation of visits to our website and what visitors do once there. This data can also be used to provide other services related to the use of our website and of the internet in general.
                            <br /><br />
                            Google states that it will not connect your IP address to other data. In addition, Google provides further information with regard to its data protection practices at

                            <br /><br /><a href="https://www.google.com/intl/de/policies/privacy/partners">https://www.google.com/intl/de/policies/privacy/partners</a><br /><br />
                            
                            including options you can exercise to prevent such use of your data.
                            <br /><br />
                            In addition, Google offers an opt-out add-on at

                            <br /><br /><a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a><br /><br />

                            in addition with further information. This add-on can be installed on the most popular browsers and offers you further control over the data that Google collects when you visit our website. The add-on informs Google Analytics' JavaScript (ga.js) that no information about the website visit should be transmitted to Google Analytics. However, this does not prevent information from being transmitted to us or to other web analytics services we may use as detailed herein.
                        </p>

                    </div>
                    <div className="col-12 col-lg-4">
                        <p>
                            Our privacy policy is structured as follows: <br />

                            <a href="#aboutus" title="Scroll to section">I. Information about us as controllers of your data</a> <br />
                            <a href="#rights" title="Scroll to section">II. The rights of users and data subjects</a> <br />
                            <a href="#aboutdata" title="Scroll to section">III. Information about the data processing</a>
                        </p>
                    </div>
                </div>
            </div>
         </section>
    )
}

export default Privacy;