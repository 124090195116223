import React, { useState, useEffect, useRef } from 'react';
import TabItem from './TabItem';
import TabContent from './TabContent';
import './ourMotivation.scss';
import Img1Tab1 from '../../../assets/imgs/image22.png';
import Img2Tab1 from '../../../assets/imgs/image21.png';
import Img1Tab2 from '../../../assets/imgs/image17.png';
import Img2Tab2 from '../../../assets/imgs/image24.png';
import ImgTab3 from '../../../assets/imgs/image16.png';

const Motivation = () => {

  const [activeTab, setActiveTab] = useState('tab1');
  const prevTab = useRef('tab1');
  const intervalTime = 8500;
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (prevTab.current === 'tab1') {
        setActiveTab('tab2');
        prevTab.current = 'tab2';
      }
      else if (prevTab.current === 'tab2') {
        setActiveTab('tab3');
        prevTab.current = 'tab3';
      }
      else if (prevTab.current === 'tab3') {
        setActiveTab('tab1');
        prevTab.current = 'tab1';
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    prevTab.current = tab;
  }

  return (
    <section className='motivation container-fluid pt-5 whiteBg' id='ourMotivation'>
      <h2 className='motivation__title mt-4 h-section'>Our motivation</h2>
      <div className='tabs row'>
        <div className='tabCol col-12 col-md-4 col-lg-5'>
          <ul className='tabnav'>
            <TabItem 
              id='tab1'
              className='mt-4'
              title='Democratise AI and make it accessible for non-technical users' 
              activeTab={activeTab}
              setActiveTab={setActiveTab} 
              intervalTime={intervalTime}
              handleTabClick={() => handleTabClick('tab1')}
            />
            <TabItem 
              id='tab2' 
              className='mt-3' 
              title='Continually drive innovation in the area of AI and its applications' 
              activeTab={activeTab} 
              setActiveTab={setActiveTab} 
              intervalTime={intervalTime}
              handleTabClick={() => handleTabClick('tab2')}
            />
            <TabItem 
              id='tab3' 
              className='mt-3' 
              title='Empower human ingenuity and maximise your business objectives with AI.' 
              activeTab={activeTab} 
              setActiveTab={setActiveTab}
              intervalTime={intervalTime} 
              handleTabClick={() => handleTabClick('tab3')}
            />
          </ul>
        </div>

        <div className='tabcontent__container col-12 col-md-7 offset-md-1 offset-lg-0'>
          <TabContent id='tab1' activeTab={activeTab}>
            <div className='tabCenter'>
              <div id='img22'>
                <img src={Img1Tab1} alt='First tab1' className='img-fluid' />
              </div>
              <div id='img21'>
                <img src={Img2Tab1} alt='Second tab1' className='img-fluid' />
              </div>
            </div>
          </TabContent>

          <TabContent id='tab2' activeTab={activeTab}>
            <div className='tabCenter'>
              <div id='img17'>
                <img src={Img1Tab2} alt='First tab2' className='img-fluid' />
              </div>
              <div id='img24'>
                <img src={Img2Tab2} alt='Second tab2' className='img-fluid' />
              </div>
            </div>
          </TabContent>

          <TabContent id='tab3' activeTab={activeTab}>
            <div className='tabCenter'>
              <div id='img16'>
                <img src={ImgTab3} alt='tab3' className='img-fluid' />
              </div>
            </div>
          </TabContent>
        </div>
      </div>
    </section>
  )
}

export default Motivation;