import React, { useEffect, useRef, useState } from 'react';
import './LoginModal.scss';
import { Box, Modal } from '@mui/material';
import { useNavigate } from "react-router-dom";

const LoginModal = ({ modalState, autentService }) => {
    let navigate = useNavigate();
    const { activeModal, setActiveModal } = modalState;
    const [modalErrorMessage, setModalErrorMessage] = useState();
    const [forgotPassword, setForgotPassword] = useState(false);
    const email = useRef();
    const [loginEmail, setLoginEmail] = useState();
    const [invalidEmail, setInvalidEmail] = useState();
    const password = useRef();
    const [loginPassword, setLoginPassword] = useState();
    const [sentEmail, setSentEmail] = useState(false);
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(_[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        if (window.location.hash === '#contactUs' && !isReloaded()) {
            setSubjectToForm();
        } else {
            setSubjectToForm('', false);
        }
    }, [])

    const isReloaded = () => {
        try {
            return performance.getEntriesByType("navigation")[0].type === 'reload';
        } catch (e) {
            console.error(e);
        }
        return false;
    }

    useEffect(() => {
        if (!activeModal) {
            resetData();
        }
    }, [activeModal]);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.keyCode === 13 && loginEmail && !invalidEmail && !sentEmail) {
                event.preventDefault();
                if (loginPassword && !forgotPassword) { //Login
                    signIn(loginEmail, loginPassword);
                } else { //Reset password
                    checkResetPassword(loginEmail);
                }
            } else if (event.keyCode === 13 && sentEmail) { //Navigate to login
                event.preventDefault();
                resetData();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [loginEmail, loginPassword, forgotPassword, invalidEmail, sentEmail]);

    const updateEmail = ({ value }) => {
        setModalErrorMessage();
        setLoginEmail(value);

        if (value && !regexEmail.test(value)) {
            setInvalidEmail({ text: 'You have entered an invalid email address' });
        } else if (invalidEmail) {
            setInvalidEmail();
        }
    }

    const updateValidity = ({ value }) => {
        setModalErrorMessage();
        setLoginPassword(value);
    }

    const signIn = async (loginEmail, loginPassword) => {
        await autentService.authenticateByEmailAndPassword(loginEmail, loginPassword).catch(() => { 
            setModalErrorMessage('Some of the fields are not correct. Please try again.');
        });
    }

    const executeIfHome = () => {
        resetData();
        setActiveModal(false);
        setSubjectToForm();
    }

    const signUp = () => {
        if (window.location.pathname !== '/') {
            navigate('/');
            setTimeout(() => {
                executeIfHome();
            }, 1000);
        } else {
            executeIfHome();
        }
    }

    const setSubjectToForm = (subjectContaint = 'Account request', navigateToScroll = true) => {
        if (navigateToScroll) {
            const domSubject = window.document.getElementsByName('TICKET.subject');
            const domConcactUs = window.document.getElementById('contactUs');
            domSubject[0].value = subjectContaint;
            //it must be set 2 times because the first scroll might triger the change of animation in the about us section
            navigateToContactUs(domConcactUs);
        }
    }

    const navigateToContactUs = (contactUsSection) => {
        setTimeout(() => { contactUsSection.scrollIntoView(); });
        setTimeout(() => { contactUsSection.scrollIntoView(); }, 1000);
    }

    const resetData = () => {
        if (email.current && email.current.value) {
            email.current.value = '';
        }
        setLoginEmail();
        if (password.current && password.current.value) {
            password.current.value = '';
        }
        setLoginPassword();
        setModalErrorMessage();
        setInvalidEmail();
        setForgotPassword();
        setSentEmail();
    }

    const checkResetPassword = (email) => {
        autentService.userExists(email).then(() => {
            autentService.recoverPasswordToken(email);
            setSentEmail(true);
        }).catch(() => {
            setModalErrorMessage('There is no account linked to this email.');
        })
    }

    const emailValidity = (!loginEmail || invalidEmail);
    const checkErrMsg = () => (modalErrorMessage && <div className='errMsg'>{modalErrorMessage}</div>);

    return <>
        <Modal
            style={{ display: 'flex' }}
            disableScrollLock={true}
            disableEscapeKeyDown={true}
            open={activeModal}
            onClose={() => {
                setActiveModal(!activeModal)
            }}
        >
            <Box className="modalWrapper">
                <Box className='modalHeader'>
                    <h5 className='modalTitle' style={{ color: 'black' }}>{forgotPassword ? 'Need a hand with your password?' : 'Account'}</h5>
                </Box>
                <Box className='modalBody'>
                    <form action="">
                        {!sentEmail && forgotPassword &&
                            <div>Let's reset it, please enter your email address. You will receive a link to create a new password via email.</div>
                        }
                        {sentEmail
                            ? <>
                                <div>Please check your email to continue the process. Don't forget to check your spam/junk folder too.</div>
                                <span>
                                    <a href='#0' onClick={() => (signUp())} >Haven’t receive the email? Contact us!</a>
                                </span>
                            </>
                            : <>
                                <label htmlFor="modalEmail" style={{ marginBottom: '5px' }}>Email</label>
                                <input
                                    ref={email}
                                    name='modalEmail'
                                    style={invalidEmail && loginEmail ? { borderColor: 'red' } : { borderColor: '#D0D5DD' }}
                                    className='form-control'
                                    onChange={(e) => { updateEmail(e.target) }}
                                    placeholder="youremail@.com"
                                    autoComplete='off'
                                />
                                {invalidEmail && <div className='errMsg'>Please enter a valid email address.</div>}
                                {!forgotPassword &&
                                    <>
                                        <label style={{ marginTop: '1.5rem' }} htmlFor="modalPassword">Password</label>
                                        <input
                                            ref={password}
                                            name='modalPassword'
                                            type='password'
                                            className='form-control'
                                            style={{ borderColor: '#D0D5DD', marginTop: '5px' }}
                                            onChange={(e) => { updateValidity(e.target) }}
                                            placeholder="password"
                                            autoComplete='on'
                                        />
                                    </>
                                }
                            </>
                        }
                    </form>
                </Box>
                <Box className='modalFooter'>
                    {forgotPassword
                        ? <>
                            {checkErrMsg()}
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                {sentEmail
                                    ? <button
                                        className='btn modalButton btn-dark wholeFtBtn'
                                        onClick={() => resetData()}>
                                        Login
                                    </button>
                                    : <button
                                        disabled={emailValidity}
                                        className={emailValidity ? 'disabled btn modalButton btn-dark wholeFtBtn' : 'btn modalButton btn-dark wholeFtBtn'}
                                        onClick={() => {
                                            checkResetPassword(loginEmail);
                                        }}>
                                        Request password reset
                                    </button>
                                }
                            </div>
                        </>
                        : <>
                            <span>
                                <a href='#0' onClick={() => {
                                    setModalErrorMessage();
                                    setForgotPassword(true);
                                }} >
                                    Forgot password?
                                </a>
                            </span>
                            {checkErrMsg()}
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <button
                                    className='btn modalButton halfFtBtn btn-dark'
                                    disabled={!(loginEmail && loginPassword) || modalErrorMessage || invalidEmail}
                                    onClick={() => (signIn(loginEmail, loginPassword))}>
                                    Login
                                </button>
                                <button
                                    className='btn modalButton halfFtBtn btn-light'
                                    style={{ borderColor: '#D0D5DD' }}
                                    onClick={() => (signUp())}>
                                    Sign Up
                                </button>
                            </div>
                        </>
                    }
                </Box>
            </Box>
        </Modal>
    </>;
}

export default LoginModal