import axios from 'axios';

export class AuthenticationClient {

    baseUrl;
    instance;

    static getInstance() {
        if (!AuthenticationClient.instance) {
            AuthenticationClient.instance = new AuthenticationClient();
        }

        return AuthenticationClient.instance;
    }

    constructor() {
        const baseUrl = '' + process.env.REACT_APP_AUTHENTICATION_API_BASE_URL
            + '' + process.env.REACT_APP_AUTHENTICATION_API_PREFIX_URI + '/auth';
        this.baseUrl = baseUrl;
    }

    async login(email, password) {
        const request = { email: email, password: password };
        const response = await axios.post(this.baseUrl + '/login', request);
        return response;
    }

    async recoverPasswordByEmail(email) {
        const request = { email: email };
        const response = await axios.post(this.baseUrl + '/soft-recover', request);
        return response;
    }

    async recoverPasswordToken(email) {
        const response = await axios.get(this.baseUrl + '/users/recoverPassword/' + email + '?mode=0');
        return response;
    }

    async checkEmailIsValid(email) {
        const body = { email: email };
        const response = await axios.post(this.baseUrl + '/email', body);
        return response;
    }

    generateHeaders(token) {
        return {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        };
    }

    async getProfile(token) {
        const headers = this.generateHeaders(token);
        const response = await axios.get(this.baseUrl + '/users/profile', {
            headers
        });
        return response;
    } 

    async resetPassword(token, newPassword, confirmPassword) {
        const headers = {'accept': 'application/json'};
        const body = { newPassword, confirmPassword, refreshToken: token };
        const response = await axios.post(this.baseUrl + '/users/resetPassword', body,  headers );
        return response;
    }

}