import React from "react";
import './footer.scss';
import AIZWEILOGO from '../../../assets/imgs/aizwei-logo.svg';
import { faFacebookF, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useForm } from '../contact/useHubspotForm'

library.add(faFacebookF, faInstagram, faTwitter, faLinkedin);

const Footer = () => {

    const { data, handleSubmit } = useForm({
        portalId: '26555333',
        formId: 'eda4e7e6-2dd5-496b-971e-82348b55883c'
    })
    if (data) {
        console.log(data.data);
    }
    return (
        <div className="container-fluid footer">
            <div className="floatingSticks">
                <span id="stick1"></span>
                <span id="stick2"></span>
                <span id="stick3"></span>
            </div>
            <div className="footerContact-1">
                <div className="logoGroup">
                    <div>
                        <img id='aizwei_logo' src={AIZWEILOGO} alt="AIZWEI LOGO" className="mb-4" />
                        <span className="fw-bold dailight mt-4"> by Dailight</span>
                    </div>
                </div>
                <div className="subscribeGroup">
                    <div className="fw-bold mt-3 ms-3 float-right" id="stayUpToDate">Stay up to date</div>
                    <form onSubmit={handleSubmit} className="btn-toolbar mb-3 mt-5 space-between float-right" id="subscribeBtn">
                            <div className="input-group m-3">
                                <input name="email" type="text" className="form-control" placeholder="Enter your email" aria-label="email" aria-describedby="email" required/>
                            </div>
                            <div className="btn-group m-3">
                                 {data && data.data && data.data.inlineMessage? <div className="fw-bold mt-2">Suscribed!</div>:<button className="btn btn-secondary rounded-pill" type="submit" id="email_subscribe">Subscribe</button>}
                            </div>
                    </form>
                </div>
            </div>
            <div className="footerContact-2">
                <div id="copyright">
                    © 2022 AI Zwei. All rights reserved.
                </div>
                <div id="TPC">
                    <a href="/impressum" style={{ color: "#98A2B3", textDecoration: "none" }} className="m-3">Impressum</a>
                    <a href="/privacy" style={{ color: "#98A2B3", textDecoration: "none" }} className="m-3">Privacy</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;
