import './App.scss';
import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthenticationService } from './services/AuthenticationService';
import NavBar from './components/navbar/navbar';
import AboutUs from './components/main/aboutUs/aboutUs';
import './components/main/aboutUs/aboutUs.scss';
import Motivation from './components/main/motivation/ourMotivation';
import Platform from './components/main/platform/ourPlatform';
import Team from './components/main/team/theTeam';
import Contact from './components/main/contact/contactUs';
import Footer from './components/main/footer/footer';
import Privacy from './components/pages/privacy/privacy';
import PreparingData from './components/pages/preparingData/preparingData';
import Impressum from './components/pages/impressum/impressum';
import LoginModal from './components/main/modals/LoginModal';
import ResetPasswordModal from './components/main/modals/reset-password/resetPassword';

const All = ({ data }) => {
	const { navWhiteState, setNavWhiteState } = data;
	return <>
		<AboutUs navWhiteState={navWhiteState} setNavWhiteState={setNavWhiteState} />
		<Motivation />
		<Platform />
		<Team />
		<Contact />
		<Footer />
	</>
}

function App() {

	const autentService = AuthenticationService.getInstance();
	const [navWhiteState, setNavWhiteState] = useState(false);
	const [activeModal, setActiveModal] = useState(false);
	const [resetPasswordShow, setResetPasswordShow] = useState(false);
	const [logo, setLogo] = useState({ src: 'white', alt: "AIZWEI LOGO WHITE" });

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has('token') && searchParams.has('mode')) {
			setResetPasswordShow(true);
		}
	}, []);

	const isNavigationOnTopOfSection = (section) => {
		const nav = document.getElementById('mainNav');

		const navRect = nav.getBoundingClientRect();
		const sectionRect = section.getBoundingClientRect();

		return navRect.bottom >= sectionRect.top && navRect.top <= sectionRect.bottom;
	}

	const changeNavColor = () => {
		document.querySelectorAll("main > section").forEach(section => {
			const isOnTop = isNavigationOnTopOfSection(section);
			if (!isOnTop) return;

			const isWhite = section.classList.contains('whiteBg');
			setNavWhiteState(isWhite);

			return false;
		});
	}

	const scrollIntoView = () => {
		const hash = window.location.hash;
		if (hash) {
			const element = document.getElementById(hash.substring(1));
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	}

	const onWindowLoad = () => {
		scrollIntoView();
		changeNavColor();
	}

	useEffect(() => {
		if (!document.readyState) {
			window.addEventListener('load', onWindowLoad);
		} else {
			onWindowLoad();
		}
	}, []);

	document.onscroll = changeNavColor;

	useEffect(() => {
		const nav = document.querySelector('.navbar');
		const navbtn = document.getElementById('elem-button');

		if (navWhiteState) {
			nav.classList.add('navbar-light');
			setLogo({ src: 'black', alt: "AIZWEI LOGO BLACK" });
			navbtn.classList.remove('light');
		} else {
			nav.classList.remove('navbar-light');
			setLogo({ src: 'white', alt: "AIZWEI LOGO WHITE" });
			navbtn.classList.add('light');
		}

	}, [navWhiteState]);

	return (
		<Router>

			<LoginModal
				autentService={autentService}
				modalState={{ activeModal: activeModal, setActiveModal: setActiveModal }}
			/>

			{
				resetPasswordShow &&
				<ResetPasswordModal
					modalState={{ activeModal: resetPasswordShow, setActiveModal: setResetPasswordShow }}
				/>
			}

			<NavBar
				modalState={{
					activeModal: activeModal,
					setActiveModal: setActiveModal
				}}
				logo={logo}
			/>

			<main>
				<Routes>
					<Route path="/preparing-your-data" element={<PreparingData />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/impressum" element={<Impressum />} />
					<Route path="/" element={<All data={{ navWhiteState, setNavWhiteState }} />} />
				</Routes>
			</main>
		</Router>
	);
}

export default App;

