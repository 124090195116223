import React from 'react';
import './preparingData.scss'
import preparedData from '../../../assets/imgs/preparedData.png';

const PreparingData = () => {
    return (
        <section className='mt-5 p-5 whiteBg'>
            <h1 className='md-mrg-bt'>Preparing Your Data for AI Zwei</h1>
            <ol>
                <li className='flexColumn md-gap'>
                    <h1 className='no-mrg-bt'>1. Preparing Structured Data</h1>
                    <p className='no-mrg-bt'>For structured data, AI Zwei-friendly formats include files with csv, tsv, xlsx and xls extensions. To prepare your data, make sure that the names of your variables (or features) are given in the top row of your Excel sheet, or text file, with one variable name per column. Also, please ensure that each column is either numerical or categorical. Regarding rows, each row should contain one instance. This arrangement can be seen in the example below for a structured dataset about diabetes in the Pima Indian community. In this example, the top row in which variable names are given is highlighted in orange.</p>
                    <img className='alingCenter' alt='example of formated data' src={preparedData}></img>
                    <p>The AIZ bot does some pre-processing to your data before starting the analysis i.e. the handling of missing values, imputation, empty columns. However, this is a feature in continuous development. Therefore, if possible, please feel free to further prepare the data by either cleaning or transforming it before starting the analysis given that higher quality data could lead to more accurate insights. Once your data is prepared, you can either directly upload the data or share the public URL link to the dataset.</p>
                </li>
            </ol>
       </section>
    )
}

export default PreparingData;