import { useEffect, useState, useRef } from 'react';
import './aboutUs.scss';
import Canvas from './canvas';

const AboutUs = ({ navWhiteState, setNavWhiteState }) => {
    const scrollPosition = useRef({ animate: true, position: 0 });
    const about = useRef(null);
    const [aboutWhiteActive, setAboutWhiteActive] = useState(false);
    const aboutWhite = () => {
        return <div className='aboutWhite whiteBg'>
            <div className="aboutWhite__inner">
                <h1>AI Zwei is an AI-powered platform that guides you through the process of making predictions and extracting insights from your data, in minutes, without technical knowledge or the need to code.</h1>
            </div>
        </div>
    }

    const aboutBlack = () => {
        return <div className='aboutDark'>
            <div className='aboutDark__inner fw-bold h1'>
                Achieve Your Goals with the
                <br />
                Power of AI in Minutes.
            </div>
        </div>
    }

    const options = {
        duration: 800,
        fill: 'forwards',
    };

    const handleScroll = () => {
        const position = about.current.scrollTop;
        const { animate } = scrollPosition.current;
        scrollPosition.current.position = position;

        const changeBackgroundWithAnimation = (colorSetter) => {
            new Promise((res) => {
                setTimeout(() => {
                    about.current.children[1].children[0].animate([
                        {
                            transform: 'translateY(0px)',
                            opacity: 1,
                        },
                    ], options);
                    res();
                }, options.duration);
            }).then(() => {
                if (colorSetter) {
                    window.scrollTo({ top: 1, left: 0, behavior: 'instant' });
                }
                setAboutWhiteActive(colorSetter);
                setNavWhiteState(colorSetter);
            });
        }

        if (position === 0 && !animate) {
            scrollPosition.current.animate = true;
            about.current.children[1].children[0].animate([
                {
                    transform: 'translateY(-50px)',
                    opacity: 0,
                },
            ], options);
            changeBackgroundWithAnimation(false);
        } else if (position > 0 && animate) {
            scrollPosition.current.animate = false;
            about.current.children[1].children[0].animate([
                {
                    transform: 'translateY(-50px)',
                    opacity: 0,
                },
            ], options);
            changeBackgroundWithAnimation(true);
        }

    };

    useEffect(() => {
        about.current.addEventListener('scroll', handleScroll, { passive: true }); // add event listener to the about section
    });

    return (
        <><section ref={about} className={aboutWhiteActive ? 'whiteBg' : 'darkBg'} id='about'>
            <Canvas />
            {aboutWhiteActive
                ? aboutWhite()
                : aboutBlack()
            }
        </section>
            {aboutWhiteActive
                ? <></>
                : <div></div>
            }
        </>

    )
}

export default AboutUs;